$(document).ready(function(){

    function initAccordions(){
        
        // reset accordions
        resetAccordions();

        // select the first accordion, add selected classes to the trigger and content, modify trigger text, and slide down content
        var firstAccordionTrigger = $('.accordion:first-child .accordionTrigger');
        var firstAccordionContent = $('.accordion:first-child .accordionContent');
        
        // now open this accordion
        // add "selected" classes
        firstAccordionTrigger.addClass('selected');
        firstAccordionContent.addClass('selected');
        // swap trigger text
        firstAccordionTrigger.children('div.accordionStatus').html('&ndash;');
        // show contents
        firstAccordionContent.slideDown();
        
    }
    
    function resetAccordions(){
        // remove all "selected" classes
        $('.accordionTrigger').removeClass('selected');
        $('.accordionContent').removeClass('selected');
        
        // close all accordionContents
        $('.accordionContent').slideUp('fast');
        
        // reset trigger text
        $('.accordionTrigger').children('div.accordionStatus').html('+');
    }

    $('.accordion .accordionTrigger').click(function(e){

        
        // accordion is already open
        if($(this).next('div').is(":visible")){
            
            // reset accordions to closed
            resetAccordions();
            
        // accordion is closed    
        } else {
            
            // reset accordions to closed
            resetAccordions();
            
            // now open this accordion
            // add "selected" classes
            $(this).addClass('selected');
            $(this).next().addClass('selected');
            // swap trigger text
            $(this).children('div.accordionStatus').html('&ndash;');
            // show contents
            $(this).next('div').slideDown();
            
        }

    });

    initAccordions();

});


$(document).ready(function(){
    "use strict";

    var mywindow;

    function orderDetailsPopup(data){

        mywindow = window.open('', 'Order Details', 'height=800,width=700');
        mywindow.document.write('<html>' +
            '<head>' +
            '<title>Order Details</title>' +
            '<link href="/assets/css/all.min.css" rel="stylesheet" media="all">' +
            '</head>' +
            '<body style="padding:50px;background-image:none;background-color:#ffffff;">');
        mywindow.document.write(data);
        mywindow.document.write('</body>' +
            '</html>');
        return true;

    }

    $(".printOrder").on("click", function(e){
        e.preventDefault();
        var details = $('.printableOrderDetails').html();
        orderDetailsPopup(details);
        //mywindow.print();
        //mywindow.close();
    });

    /*
    ********************************
    * GENERAL FUNCTIONS 
    ********************************
    */

    /*
     * CLEAR SHIPPING FIELDS
     */
    function clearShippingFields(){
        $("#shipping_first_name").val('');
        $("#shipping_last_name").val('');
        $("#shipping_company").val('');
        $("#shipping_address").val('');
        $("#shipping_address2").val('');
        $("#shipping_city").val('');
        $("#shipping_state").val('');
        $("#shipping_province_territory").val('');
        $("#shipping_zip").val('');
        $("#shipping_country_code").val('');
    };

    /*
     * MAKE SHIPPING FIELDS REQUIRED
     */
    function makeShippingFieldsRequired(){
        $("#shipping_first_name").prop('required', true);
        $("#shipping_last_name").prop('required', true);
        $("#shipping_address").prop('required', true);
        $("#shipping_city").prop('required', true);
        $("#shipping_state").prop('required', true);
        $("#shipping_province_territory").prop('required', true);
        $("#shipping_zip").prop('required', true);
        $("#shipping_country_code").prop('required', true);
    };

    /*
     * REMOVE SHIPPING FIELDS REQUIRED
     */
    function removeShippingFieldsRequired(){
        $("#shipping_first_name").prop('required', false);
        $("#shipping_last_name").prop('required', false);
        $("#shipping_address").prop('required', false);
        $("#shipping_city").prop('required', false);
        $("#shipping_state").prop('required', false);
        $("#shipping_province_territory").prop('required', false);
        $("#shipping_zip").prop('required', false);
        $("#shipping_country_code").prop('required', false);
    };

    /*
     * SHOW SHIPPING FIELDS
     */
    function displayShippingFields(){
        $('#shippingInfoWrapper').show();
    };

    /*
     * TOGGLE SHIPPING FIELDS
     */
    function toggleShippingFields(){
        if($('input:radio[name=shipping_option]:checked').val() === 'delivery'){
            displayShippingFields();
            makeShippingFieldsRequired();
        } else {
            clearShippingFields();
            removeShippingFieldsRequired();
            $('#shippingInfoWrapper').hide();
        }
    };

    /*
    ********************************
    * EVENT HANDLERS
    ********************************
    */

    /*
     * CLEAR CART BUTTON
     */
    $('.clearCartButton').on('click', function(e){

        e.preventDefault();

        var url     = e && (('string' === typeof e && e) || (e.currentTarget && e.currentTarget.attributes.href.value));
        var title   = 'Clear Cart';
        var message = 'Remove all items from your cart?';

        $.confirm({
                      'title': title,
                      'message': message,
                      'buttons': {
                          'Yes': {
                              'class': 'confirmPositive',
                              'action': function(){
                                  if(url){
                                      window.location = url;
                                  } else {
                                      return false;
                                  }
                              }
                          },
                          'No': {
                              'default': true,
                              'class': 'confirmNegative',
                              'action': function(){
                                  return false;
                              }
                          }
                      }

                  });

    });

    /*
     * EDIT CART ITEM BUTTON
     */
    $('.editCartItemButton').on('click', function(e){

        e.preventDefault();

        var cartItemForm = $("#" + $(this).attr("data-form"));
        cartItemForm.find('.cartItemEditable').removeClass('disabled');
        
        // enable, remove disabled class, and set old value
        var editableTextInput = cartItemForm.find('.cartItemEditable input[type=text]');
        editableTextInput.each(function(){
            $(this).prop("disabled", false);
            $(this).removeClass('disabled');
            $(this).attr('data-old-value', $(this).val());
        });
        var editableTextarea = cartItemForm.find('.cartItemEditable textarea');
        editableTextarea.each(function(){
            $(this).prop("disabled", false);
            $(this).removeClass('disabled');
            $(this).attr('data-old-value', $(this).val());
        });
        var editableSelect = cartItemForm.find('.cartItemEditable select');
        editableSelect.each(function(){
            $(this).prop("disabled", false);
            $(this).removeClass('disabled');
            $(this).attr('data-old-value', $(this).val());
        });

        // hide all item edit/delete buttons
        var thisEdit = $('.cartEditRemove');
        thisEdit.animate({
            right: parseInt(thisEdit.css('right'),10) == 0 ? -170 : 0
        });
        
        // show this action buttons
        var thisUpdate = cartItemForm.find('.cartCancelUpdate');
        thisUpdate.animate({
            right: parseInt(thisUpdate.css('right'),10) == 0 ? 170 : 0
        });

    });

    /*
     * CANCEL EDIT CART ITEM BUTTON
     */
    $('.cancelCartItemButton').on('click', function(e){

        e.preventDefault();

        var cartItemForm = $("#" + $(this).attr("data-form"));
        cartItemForm.find('.cartItemEditable').addClass('disabled');
        
        // disable, add disabled class, and set old value
        var editableTextInput = cartItemForm.find('.cartItemEditable input[type=text]');
        editableTextInput.each(function(){
            $(this).prop("disabled", true);
            $(this).addClass('disabled');
            $(this).val($(this).attr('data-old-value'));
        });
        var editableTextarea = cartItemForm.find('.cartItemEditable textarea');
        editableTextarea.each(function(){
            $(this).prop("disabled", true);
            $(this).addClass('disabled');
            $(this).val($(this).attr('data-old-value'));
        });
        var editableSelect = cartItemForm.find('.cartItemEditable select');
        editableSelect.each(function(){
            $(this).prop("disabled", true);
            $(this).addClass('disabled');
            $(this).val($(this).attr('data-old-value'));
        });

        // hide all item edit/delete buttons
        var thisEdit = $('.cartEditRemove');
        thisEdit.animate({
            right: parseInt(thisEdit.css('right'),10) == 0 ? 170 : 0
        });
        
        // show this action buttons
        var thisUpdate = cartItemForm.find('.cartCancelUpdate');
        thisUpdate.animate({
            right: parseInt(thisUpdate.css('right'),10) == 0 ? -170 : 0
        });

    });
    
    /*
     * UPDATE CART BUTTON
     */
    $('.updateCartItemButton').on('click', function(e){
        e.preventDefault();
        document.body.style.cursor = 'wait';
        var cartForm               = $("#" + $(this).attr("data-form"));
        cartForm.submit();
    });

    /*
     * DELETE CART ITEM BUTTON
     */
    $('.deleteCartItemButton').on('click', function(e){

        e.preventDefault();

        var cartForm           = $("#" + $(this).attr("data-form"));
        var deleteItemCheckbox = $("#" + $(this).attr("data-delete"));

        var title   = 'Remove Item';
        var message = 'Remove item from your cart?';
        $.confirm({
                      'title': title,
                      'message': message,
                      'buttons': {
                          'Yes': {
                              'class': 'confirmPositive',
                              'action': function(){
                                  $(".cartDeleteItemCheckbox").prop('checked', false);
                                  $(deleteItemCheckbox).prop('checked', true);
                                  document.body.style.cursor = 'wait';
                                  cartForm.submit();
                              }
                          },
                          'No': {
                              'default': true,
                              'class': 'confirmNegative',
                              'action': function(){
                                  return false;
                              }
                          }
                      }
                  });

    });

    /*
     * COPY BILLING INFO TO SHIPPING BUTTON
     */
    $("#copyBillingInfo").on('click', function(e){

        e.preventDefault();

        $("#shipping_first_name").val($("#first_name").val());
        $("#shipping_last_name").val($("#last_name").val());
        $("#shipping_company").val($("#company").val());
        $("#shipping_address").val($("#address").val());
        $("#shipping_address2").val($("#address2").val());
        $("#shipping_city").val($("#city").val());
        $("#shipping_state").val($("#state").val());
        $("#shipping_province_territory").val($("#billing_province_territory").val());
        $("#shipping_zip").val($("#zip").val());
        $("#shipping_country_code").val($("#country_code").val());
        $("#shipping_email").val($("#customer_email_address").val());
        $("#shipping_phone").val($("#customer_phone").val());
        $("#shipping_country_code").val($("#country_code").val());
    });

    /*
     * INITIALIZE CART CONTENTS PAGE
     */
    function initCartContentsPage(){
        $('.cartItemEditable input[type=text]').prop("disabled", true).addClass('disabled');
        $('.cartItemEditable select').prop("disabled", true).addClass('disabled');
        $('.cartItemEditable textarea').prop("disabled", true).addClass('disabled');
    }

    /*
     * INITIALIZE YOUR INFORMATION PAGE
     */
    function initYourInformationPage(){
        toggleShippingFields();
    }

    if($('body').attr('id') === 'your-information'){
        initYourInformationPage();
    } else if($('body').attr('id') === 'your-cart'){
        initCartContentsPage();
    }
    
    $('#checkoutForm').submit(function(){
        $("#submitOrderButton").prop("disabled", true).addClass('submitDisabled');
    });

});

function setCookie(name,value,days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 86400));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
function eraseCookie(name) {   
    document.cookie = name+'=; Max-Age=date.getTime() - 86400;';  
}

$(document).ready(function(){

    function initIndexBanner(){
        var indexBannerAd = $('.indexBannerAd');
        var bannerHeight  = indexBannerAd.height();
        indexBannerAd.css('height', bannerHeight + 'px');
        indexBannerAd.css('bottom', '-' + bannerHeight + 'px');
        if(!getCookie('exp_seen_banner')){
            indexBannerAd.animate({
                                      bottom: "0"
                                  }, 500);
        }
    }

    $(document).on('click', '.closeBanner', function(){
        setCookie('exp_seen_banner', 'y', 86400);
        var indexBannerAd = $('.indexBannerAd');
        var bannerHeight  = indexBannerAd.height();
        indexBannerAd.animate({
                                  bottom: '-' + bannerHeight + 'px'
                              }, 200);
    });

    /* remove all but the first one */
    $('.indexBannerAd .bannerAdImageWrapper').slice(1).remove();
    setTimeout(initIndexBanner, 1500);

});


$(document).ready(function(){

    /*if(!('IntersectionObserver' in window)){*/

    /* unsupported, just load everything */
    /*
    $("[data-src]").each(function(){
        $(this).attr('srcset', $(this).data("srcset"));
        $(this).removeAttr("data-srcset");
        $(this).attr('src', $(this).data("src"));
        $(this).removeAttr("data-src");
    });
    */

    /*} else {*/

    function preloadImage(img){
        var src    = img.getAttribute('data-src');
        var srcset = img.getAttribute('data-srcset');
        if(!src){
            return;
        }

        img.srcset = srcset;
        img.src    = src;
        img.onload = function(){
            img.removeAttribute('data-src');
            img.removeAttribute('data-srcset');
        };

    }
    
    var observer = new IntersectionObserver(function(entries, self){
        entries.forEach(function(entry){
            if(entry.isIntersecting){
                preloadImage(entry.target);
                self.unobserve(entry.target);
            }
        });
    }, {
        rootMargin: '0px 0px 50px 0px',
        threshold: 0
        });

    $("img[data-src]").each(function(){
        observer.observe(this);
    });

    /*}*/

});

/**
 * required for location maps
 */
var markers       = [];
var location_data = [
    [
        '<div class="locationInfoWindow"><h3>Food</h3></div>',
        35.0874208,
        -92.4160903,
        1,
        'food'
    ],
    [
        '<div class="locationInfoWindow"><h3>Park 1</h3></div>',
        35.7861855,
        -91.64286523,
        2,
        'park'
    ],
    [
        '<div class="locationInfoWindow"><h3>Park 2</h3></div>',
        34.8951855,
        -92.9418652,
        3,
        'park'
    ]
];
var icons         = {
    "food": '/assets/graphics/map-marker-food.png',
    "park": '/assets/graphics/map-marker-park.png'
};
var map_style     = [
    {elementType: 'geometry', stylers: [{color: '#ebe3cd'}]},
    {elementType: 'labels.text.fill', stylers: [{color: '#523735'}]},
    {elementType: 'labels.text.stroke', stylers: [{color: '#f5f1e6'}]},
    {
        featureType: 'administrative',
        elementType: 'geometry.stroke',
        stylers: [{color: '#c9b2a6'}]
    },
    {
        featureType: 'administrative.land_parcel',
        elementType: 'geometry.stroke',
        stylers: [{color: '#dcd2be'}]
    },
    {
        featureType: 'administrative.land_parcel',
        elementType: 'labels.text.fill',
        stylers: [{color: '#ae9e90'}]
    },
    {
        featureType: 'landscape.natural',
        elementType: 'geometry',
        stylers: [{color: '#dfd2ae'}]
    },
    {
        featureType: 'poi',
        elementType: 'geometry',
        stylers: [{color: '#dfd2ae'}]
    },
    {
        featureType: 'poi',
        elementType: 'labels.text.fill',
        stylers: [{color: '#93817c'}]
    },
    {
        featureType: 'poi.park',
        elementType: 'geometry.fill',
        stylers: [{color: '#a5b076'}]
    },
    {
        featureType: 'poi.park',
        elementType: 'labels.text.fill',
        stylers: [{color: '#447530'}]
    },
    {
        featureType: 'road',
        elementType: 'geometry',
        stylers: [{color: '#f5f1e6'}]
    },
    {
        featureType: 'road.arterial',
        elementType: 'geometry',
        stylers: [{color: '#fdfcf8'}]
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry',
        stylers: [{color: '#f8c967'}]
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.stroke',
        stylers: [{color: '#e9bc62'}]
    },
    {
        featureType: 'road.highway.controlled_access',
        elementType: 'geometry',
        stylers: [{color: '#e98d58'}]
    },
    {
        featureType: 'road.highway.controlled_access',
        elementType: 'geometry.stroke',
        stylers: [{color: '#db8555'}]
    },
    {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [{color: '#806b63'}]
    },
    {
        featureType: 'transit.line',
        elementType: 'geometry',
        stylers: [{color: '#dfd2ae'}]
    },
    {
        featureType: 'transit.line',
        elementType: 'labels.text.fill',
        stylers: [{color: '#8f7d77'}]
    },
    {
        featureType: 'transit.line',
        elementType: 'labels.text.stroke',
        stylers: [{color: '#ebe3cd'}]
    },
    {
        featureType: 'transit.station',
        elementType: 'geometry',
        stylers: [{color: '#dfd2ae'}]
    },
    {
        featureType: 'water',
        elementType: 'geometry.fill',
        stylers: [{color: '#b9d3c2'}]
    },
    {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [{color: '#92998d'}]
    }
];

function initMap(){

    window.map = new google.maps.Map(document.getElementById('gmap'), {
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        gestureHandling: 'cooperative'
    });

    var infowindow = new google.maps.InfoWindow();
    var bounds     = new google.maps.LatLngBounds();

    for(i = 0; i < location_data.length; i++){

        location_category = location_data[i][4];
        marker            = new google.maps.Marker({
                                                       position: new google.maps.LatLng(location_data[i][1], location_data[i][2]),
                                                       map: map,
                                                       icon: {
                                                           url: icons[location_category],
                                                           // This marker is 24x32
                                                           size: new google.maps.Size(24, 32),
                                                           // The origin for this image is top left
                                                           origin: new google.maps.Point(0, 0),
                                                           // The anchor for this image bottom center
                                                           anchor: new google.maps.Point(12, 32)
                                                       },
                                                       animation: google.maps.Animation.DROP,
                                                       entry_id: location_data[i][3]
                                                   });

        // make sure map keeps marker in bounds
        bounds.extend(marker.position);

        google.maps.event.addListener(marker, 'click', (function(marker, i){
            return function(){
                infowindow.setContent(location_data[i][0]);
                infowindow.open(map, marker);
            }
        })(marker, i));

        markers.push(marker);
    }

    map.fitBounds(bounds);
    map.setOptions({styles: map_style});

    var listener = google.maps.event.addListener(map, "idle", function(){
        map.setZoom(6);
        google.maps.event.removeListener(listener);
    });

}

$(document).ready(function(){

    // Add click listener to html block
    $(document).on('click', '.mapTrigger', function(e){
        e.preventDefault();
        $('html,body').animate({
                               scrollTop: $("#gmap").offset().top - $('#pageInnerWrapper').offset().top,
                           }, 500, "swing");
        var entry_id = $(this).attr('data-entry_id');
        $.each(markers, function(index, markerObject){
            if(markerObject.entry_id == entry_id){
                google.maps.event.trigger(markers[index], 'click');
            }
        });
    });

});


$(document).ready(function(){

    /*
     *  REQUIRED for responsive menu
     *  add button to open submenus
     *  ****************************
     */
    $('#mobileNavigation .mobileMainMenu li').each(function(){
        if($(this).children('ul').length > 0){
            $(this).children(":first").prepend('<span class="openSubMenu"></span>');
            $(this).addClass('hasChild');
        }
    });
    
    $(document).on('click', '.mobileMainMenu .openSubMenu', function(e){

        e.preventDefault();

        var parentLi = $(this).closest("li");
        var auntLi = parentLi.siblings("li");

        // close submenus
        auntLi.find("ul").slideUp();
        parentLi.find("ul").slideUp();
        
        if(!parentLi.find("ul:first").is(":visible")){
            auntLi.find(".subMenuOpen").removeClass('subMenuOpen');
            $(this).addClass('subMenuOpen');
            parentLi.find("ul:first").slideDown();
        } else {
            parentLi.find(".subMenuOpen").removeClass('subMenuOpen');
        }
        
    });
    
});

$(document).ready(function(){

    function fixIeObjectFit(){

        $('.objectFitImage').each(function(){
            var img    = $(this).find('img');
            var imgURL = img.attr('src');
            if(!imgURL){
                imgURL = img.attr('data-src');
            }
            img.hide();
            $(this).css('background-image', 'url(' + imgURL + ')');
            $(this).css('background-size', 'cover');
            $(this).css('background-position', 'center center');
        });

    }

    // Detect objectFit support
    if(document.createElement("detect").style.objectFit === ""){
        return;

    } else {
        fixIeObjectFit();

    }

});


$(document).ready(function(){

    function initTabs(){
        $(".tabContent").hide(); // Hide all content
        $(".tabs > ul li:first-child").attr("class", "active"); // Activate the first tab
        $(".tabContent:first-child").show(); // Show first tab's content
    }

    $(".tabContent").hide(); // Hide all content
    $(".tabs > ul li:first-child").attr("class", "active"); // Activate the first tab
    $(".tabContent:first-child").show(); // Show first tab's content

    $('.tabs > ul li a').click(function(e){
        e.preventDefault();
        if($(this).closest("li").attr("class") == "active"){ //detection for current tab
            return;
        } else {
            $(".tabContent").hide(); // Hide all content
            $(".tabs > ul li").removeClass('active');
            $(this).parent().toggleClass('active'); // Activate this
            $('#' + $(this).attr('name')).show(); // Show content for the current tab
        }
    });

    initTabs();
    
});

