$(document).ready(function(){

    $.validator.addMethod("scvems", function(value, element){
        var re = new RegExp("^[a-zA-Z0-9 \-]+$");
        return this.optional(element) || re.test(value);
    }, "Letters, numbers, dash, or space only.");

    $.validator.addMethod("lrems", function(value, element){
        return this.optional(element) || /^[0-9 \-]+$/i.test(value);
        var re = new RegExp("^[0-9 \-]+$");
        return this.optional(element) || re.test(value);
    }, "Numbers, dash, or space only.");

    $("#addScvemsPaymentToCartForm").validate({
                                                  rules: {
                                                      "item_options[call_number]": {
                                                          required: true,
                                                          scvems: true
                                                      },
                                                      "item_options[patient_name]": "required",
                                                      "price": "required"
                                                  },
                                                  errorElement: 'div'
                                              });

    $("#addLremsPaymentToCartForm").validate({
                                                 rules: {
                                                     "item_options[call_number]": {
                                                         required: true,
                                                         lrems: true
                                                     },
                                                     "item_options[patient_name]": "required",
                                                     "price": "required"
                                                 },
                                                 errorElement: 'div'
                                             });

});

$(document).ready(function(){

    $("a[rel^='prettyPhoto']").prettyPhoto({
                                               overlay_gallery: false,
                                               social_tools: false,
                                               slideshow: false,
                                               allow_resize: true,
                                               deeplinking: false,
                                           });

    $("a[rel^='external']").on('click', function(e){
        e.preventDefault();
        window.open(this.href);
    });

    /*
    * submit a form on select option change
    */
    $('.submitOnChange').on('change', function(){
        form = $(this).closest("form");
        form.submit();
    });

    $('.gotoOnChange').on('change', function(){
        url                  = $(this).val();
        window.location.href = url;
    });

    /*
    * disable links
    */
    $(".disabled").on('click', function(e){
        e.preventDefault();
    });



});

